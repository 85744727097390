import { Injectable } from '@angular/core';

@Injectable()
export class MajlisService {
  constructor() {
  }

  // NOTE: when copy-pasting, don't forget closing braces for class etc.
  public majalis = [
        {
            "majlis": "Albion",
            "has_halqa": false,
            "khuddam": 5,
            "atfal": 1,
            "last_updated": "2019-01-07",
            "requires_update": false,
            "short_code": "ALBN"
        },
        {
            "majlis": "Casernes",
            "has_halqa": false,
            "khuddam": 7,
            "atfal": 1,
            "last_updated": "2017-12-21",
            "requires_update": true,
            "short_code": "CSRN"
        },
        {
            "majlis": "Cassis",
            "has_halqa": false,
            "khuddam": 13,
            "atfal": 5,
            "last_updated": "2017-12-21",
            "requires_update": true,
            "short_code": "CASS"
        },
        {
            "majlis": "Curepipe",
            "has_halqa": false,
            "khuddam": 16,
            "atfal": 14,
            "last_updated": "2017-12-21",
            "requires_update": true,
            "short_code": "CRPE"
        },
        {
            "majlis": "Flacq",
            "has_halqa": false,
            "khuddam": 6,
            "atfal": 2,
            "last_updated": "2017-12-21",
            "requires_update": true,
            "short_code": "FLCQ"
        },
        {
            "majlis": "Gentilly",
            "has_halqa": false,
            "khuddam": 17,
            "atfal": 10,
            "last_updated": "2017-12-21",
            "requires_update": true,
            "short_code": "GENT"
        },
        {
            "majlis": "Montagne Blanche",
            "has_halqa": false,
            "khuddam": 29,
            "atfal": 11,
            "last_updated": "2017-12-21",
            "requires_update": true,
            "short_code": "MBLN"
        },
        {
            "majlis": "Montagne Longue",
            "has_halqa": false,
            "khuddam": 3,
            "atfal": 0,
            "last_updated": "2017-12-21",
            "requires_update": true,
            "short_code": "MLNG"
        },
        {
            "majlis": "New Grove",
            "has_halqa": false,
            "khuddam": 4,
            "atfal": 0,
            "last_updated": "2017-12-21",
            "requires_update": true,
            "short_code": "NGRV"
        },
        {
            "majlis": "Pailles",
            "has_halqa": false,
            "khuddam": 22,
            "atfal": 12,
            "last_updated": "2017-12-21",
            "requires_update": true,
            "short_code": "PAIL"
        },
        {
            "majlis": "Phoenix",
            "has_halqa": false,
            "khuddam": 34,
            "atfal": 8,
            "last_updated": "2017-12-21",
            "requires_update": true,
            "short_code": "PHNX"
        },
        {
            "majlis": "Quartier Militaire",
            "has_halqa": false,
            "khuddam": 10,
            "atfal": 4,
            "last_updated": "2017-12-21",
            "requires_update": true,
            "short_code": "QMIL"
        },
        {
            "majlis": "Quatre Bornes",
            "has_halqa": true,
            "khuddam": 85,
            "atfal": 21,
            "last_updated": "2019-01-07",
            "requires_update": false,
            "short_code": "QBRN",
            "halqas": [
                {
                    "halqa": "All",
                    "khuddam": 0,
                    "atfal": 0
                },
                {
                    "halqa": "Centre",
                    "khuddam": 34,
                    "atfal": 4
                },
                {
                    "halqa": "East",
                    "khuddam": 29,
                    "atfal": 11
                },
                {
                    "halqa": "West",
                    "khuddam": 22,
                    "atfal": 6
                }
            ]
        },
        {
            "majlis": "Rose Hill",
            "has_halqa": true,
            "khuddam": 94,
            "atfal": 31,
            "last_updated": "2017-12-21",
            "requires_update": true,
            "short_code": "RHIL",
            "halqas": [
                {
                    "halqa": "All",
                    "khuddam": 0,
                    "atfal": 31
                },
                {
                    "halqa": "Belle-Rose",
                    "khuddam": 17,
                    "atfal": 0
                },
                {
                    "halqa": "Centre",
                    "khuddam": 34,
                    "atfal": 0
                },
                {
                    "halqa": "North-East",
                    "khuddam": 16,
                    "atfal": 0
                },
                {
                    "halqa": "Plaisance",
                    "khuddam": 9,
                    "atfal": 0
                },
                {
                    "halqa": "West",
                    "khuddam": 18,
                    "atfal": 0
                }
            ]
        },
        {
            "majlis": "Saint Pierre",
            "has_halqa": false,
            "khuddam": 28,
            "atfal": 11,
            "last_updated": "2018-03-17",
            "requires_update": true,
            "short_code": "STPR"
        },
        {
            "majlis": "Stanley",
            "has_halqa" : false,
            "khuddam": 18,
            "atfal": 10,
            "last_updated": "2017-12-21",
            "requires_update": true,
            "short_code": "STAN"
        },
        {
            "majlis": "Trefles",
            "has_halqa": true,
            "khuddam": 45,
            "atfal": 11,
            "last_updated": "2017-12-21",
            "requires_update": true,
            "short_code": "TREF",
            "halqas": [
                {
                    "halqa": "All",
                    "khuddam": 1,
                    "atfal": 0
                },
                {
                    "halqa": "Centre",
                    "khuddam": 14,
                    "atfal": 2
                },
                {
                    "halqa": "North",
                    "khuddam": 17,
                    "atfal": 7
                },
                {
                    "halqa": "West",
                    "khuddam": 13,
                    "atfal": 2
                }
            ]
        },
        {
            "majlis": "Triolet",
            "has_halqa": false,
            "khuddam": 19,
            "atfal": 6,
            "last_updated": "2017-12-21",
            "requires_update": true,
            "short_code": "TRIO"
        },
        {
            "majlis": "All",
            "has_halqa": false,
            "khuddam": 455,
            "atfal": 158,
            "last_updated": "2017-12-21",
            "requires_update": true,
            "short_code": "ALL"
        }
    ];

}
