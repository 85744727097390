import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '../services/global.service';
import { BackendService } from '../services/backend.service'
import { GetMissingReportsForMonthRequest } from '../requests-and-responses/get-missing-reports-for-month-request';
@Component({
  selector: 'app-missing-reports',
  templateUrl: './missing-reports.component.html',
  styleUrls: ['./missing-reports.component.css']
})
export class MissingReportsComponent implements OnInit {
  public user: any;
  public monthsAll: string[] = ["November (prev year)", "December (prev year)", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  public monthAvailable: string[] = [];
  public sel_month;
  public sel_year: number = new Date().getFullYear();

  public getMissingReportsForMonthRequest;
  public tableDataOriginal: any;
  public tableData: any;
  public headers = [
    { "key": "Majlis", "value": "MAJLIS" },
    { "key": "Atfal", "value": "ATFAL" },
    { "key": "Isha’at", "value": "ISHAAT" },
    { "key": "Khidmate Khalq", "value": "KHIDM" },
    { "key": "Maal", "value": "MAAL" },
    { "key": "Mu’tamad", "value": "MU'TMD" },
    { "key": "Sihat-e-Jismani", "value": "SIHAT" },
    { "key": "Ta’leem", "value": "TALEEM" },
    { "key": "Tabligh", "value": "TABLGH" },
    { "key": "Tarbiyyat", "value": "TARBYT" },
    { "key": "Ummumi", "value": "UMMUMI" },
    { "key": "Waqare Amal", "value": "WQRMAL" }
  ];

  constructor(private router: Router, public global: GlobalService, private backendService: BackendService) {
    this.global.loading("fetching reports");
  }

  fetchReport() {
    this.backendService.sendAny(this.getMissingReportsForMonthRequest)
      .subscribe(response => {
        this.global.loadingComplete();
        if (response.result == "error") {
          this.global.showMessage("Invalid request. " + response.error, true);
        } else {
          this.tableDataOriginal = response.data;
          this.treatReport();
        }
      }, err => {
        this.global.loadingComplete();
        this.global.showMessage("Failed to fetch report.", true);
      });
  }

  treatReport() {
    if (this.tableDataOriginal.length > 0) {
      this.tableData = [];
      var majlisReported = {};
      for (var i in this.tableDataOriginal) {
        majlisReported[this.tableDataOriginal[i].Majlis] = this.tableDataOriginal[i];
      }

      for (var majlisIndex in this.global.majalis) {
        if (this.global.majalis[majlisIndex].majlis != "All"
          && this.global.majalis[majlisIndex].majlis != "La Ferme"
          && this.global.majalis[majlisIndex].majlis != "Rodrigues"
          && this.global.majalis[majlisIndex].majlis != "Port Mathurin") {
          if (majlisReported[this.global.majalis[majlisIndex].majlis] == undefined) {
            var newMajlis = { "Majlis": this.global.majalis[majlisIndex] };
            for (var i in this.headers) {
              if (this.headers[i].key != "Majlis") {
                newMajlis[this.headers[i].value] = "";
              }
            }
            this.tableData.push(newMajlis);
          } else {
            majlisReported[this.global.majalis[majlisIndex].majlis].Majlis = this.global.majalis[majlisIndex];
            this.tableData.push(majlisReported[this.global.majalis[majlisIndex].majlis]);
          }
        }
      }
    }
  }

  getZeroIndexMonthFromString(mon) {
    var d = Date.parse(mon + "1, 2012");
    if (!isNaN(d)) {
      return new Date(d).getMonth();
    }
    return new Date().getMonth();
  }

  updateReport() {
    if (this.sel_month.includes("(prev year)")) {
      this.sel_year = new Date().getFullYear() - 1;
    } else {
      this.sel_year = new Date().getFullYear();
    }
    var month = this.getZeroIndexMonthFromString(this.sel_month.replace(" (prev year)", ""));
    var year = this.sel_year;
    this.getMissingReportsForMonthRequest = new GetMissingReportsForMonthRequest(month,year);
    this.global.loading("fetching reports");
    this.fetchReport();
  }

  ngOnInit() {
    if (sessionStorage.user == null && localStorage.user == null) {
      this.global.loadingComplete();
      this.router.navigate(['/login']);
    } else {
      if (localStorage.user != null) {
        sessionStorage.user = localStorage.user;
      }
    }

    this.global.minimiseHeader = true;
    this.user = JSON.parse(sessionStorage.user);

    this.monthAvailable.push(this.monthsAll[new Date().getMonth()]);
    this.monthAvailable.push(this.monthsAll[new Date().getMonth() + 1]);
    this.monthAvailable.push(this.monthsAll[new Date().getMonth() + 2]);
    this.sel_month = this.monthAvailable[2];
    //SELECT C, count(C) WHERE year(G)=2017 AND month(G)=11 GROUP BY C pivot E

    var month = new Date().getMonth();
    var year = this.sel_year;
    //"SELECT C,D,E,count(E) WHERE month(H)="+month+" AND year(H)="+year+" AND C!='All' GROUP BY E,C,D ORDER BY C,D,E LABEL count(E) 'hide_count'"
    this.getMissingReportsForMonthRequest = new GetMissingReportsForMonthRequest(month,year);
    this.fetchReport();
  }

}
