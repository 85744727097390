export class WeeklyNamazReportRequest {
  public cmd: string = "add_weekly_namaz_report";
  public username: string; // reporter
  public password: string; // report_verify
  public majlis: string;
  public halqa: string;
  public week_text: string;
  public fajr: number;
  public maghrib: number;
  public isha: number;
  public total_khuddam_at_date: number;
  public per_khuddam_fajr: number;
  public per_khuddam_maghrib: number;
  public per_khuddam_isha: number;
  public comments: string;

  constructor(username, password, majlis) {
    this.username = username;
    this.password = password;
    this.majlis = majlis;
  }
}
