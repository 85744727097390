export class OneLineReportRequest {
  public cmd: string = "add_one_line_report";
  public username: string; // reporter
  public password: string; // report_verify
  public majlis: string;
  public halqa: string;
  public office: string;
  public event_type: string;
  public event: string;
  public date: string;
  public attendance_khuddam: number;
  public attendance_atfal: number;
  public total_khuddam_at_date: number;
  public total_atfal_at_date: number;
  public percentage_khuddam: number;
  public percentage_atfal: number;
  public comments: string;

  constructor(username, password, majlis) {
    this.username = username;
    this.password = password;
    this.majlis = majlis;
  }
}
