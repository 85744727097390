import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertToLocalDate'
})
export class ConvertToLocalDatePipe implements PipeTransform {

  transform(value: string): string {
    var date = new Date(value);
    var month = (date.getMonth() + 1);
    var day = date.getDate();
    return date.getFullYear() + "-" + (month < 10 ? "0" : "") + month + "-" + (day < 10 ? "0" : "") + day;
  }

}
