import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '../services/global.service';
import { BackendService } from '../services/backend.service'
import { WeeklyNamazReportRequest } from '../requests-and-responses/weekly-namaz-report-request';

import { Observable } from 'rxjs/Observable';

@Component({
  selector: 'app-weekly-namaz-report',
  templateUrl: './weekly-namaz-report.component.html',
  styleUrls: ['./weekly-namaz-report.component.css']
})
export class WeeklyNamazReportComponent implements OnInit {
  public weekRanges: string[] = [];
  public showLabel: boolean = true;

  public weeklyNamazRequest: WeeklyNamazReportRequest;
  public sel_majlis: any = {};
  public sel_halqa: any = {};
  public sel_weekrange: string;
  public count_fajr: number;
  public count_maghrib: number;
  public count_isha: number;
  public comments: string;

  public updateHalqa() {
    if (this.sel_majlis.has_halqa) {
      for (var halqaIndex = 0; halqaIndex < this.sel_majlis.halqas.length; halqaIndex++) {
        if (this.sel_majlis.halqas[halqaIndex].halqa == "All") {
          this.sel_halqa = this.sel_majlis.halqas[halqaIndex];
          break;
        }
      }
    } else {
      this.sel_halqa = undefined;
    }
  }

  public submit() {
    this.weeklyNamazRequest.majlis = this.sel_majlis.majlis;
    if (!this.sel_majlis.has_halqa) {
      this.sel_halqa = undefined;
      this.weeklyNamazRequest.halqa = "N/A";
    } else {
      this.weeklyNamazRequest.halqa = this.sel_halqa.halqa;
    }
    this.weeklyNamazRequest.week_text = this.sel_weekrange;
    this.weeklyNamazRequest.fajr = this.count_fajr;
    this.weeklyNamazRequest.maghrib = this.count_maghrib;
    this.weeklyNamazRequest.isha = this.count_isha;
    if (!this.sel_majlis.has_halqa || this.sel_halqa.halqa == "All") {
      this.weeklyNamazRequest.total_khuddam_at_date = this.sel_majlis.khuddam;
    } else {
      this.weeklyNamazRequest.total_khuddam_at_date = this.sel_halqa.khuddam;
    }
    var percent_ratio = 100 / this.weeklyNamazRequest.total_khuddam_at_date;
    this.weeklyNamazRequest.per_khuddam_fajr = percent_ratio * this.weeklyNamazRequest.fajr;
    this.weeklyNamazRequest.per_khuddam_maghrib = percent_ratio * this.weeklyNamazRequest.maghrib;
    this.weeklyNamazRequest.per_khuddam_isha = percent_ratio * this.weeklyNamazRequest.isha;
    this.weeklyNamazRequest.comments = this.comments == undefined? "" : this.comments;

    this.global.loading("sending report");
    this.sel_weekrange = undefined;
    this.sel_halqa = this.sel_majlis.has_halqa ? this.sel_majlis.halqas[0] : undefined;
    this.count_fajr = null;
    this.count_maghrib = null;
    this.count_isha = null;
    this.comments = "";

    this.backendService.sendAny(this.weeklyNamazRequest)
      .subscribe(response => {
        this.global.loadingComplete();
        if (response.result == "error") {
          this.global.showMessage("Invalid request. " + response.error, true);
        } else {
          this.global.showMessage("Report sent, Jazakumullah.", false);
        }
      }, err => {
        this.global.loadingComplete();
        this.global.showMessage("Failed to send report.", true);
      });
  }


  populateWeekRanges() {
    var d = new Date();
    // set to last Friday of this week (or today if it's Friday)
    d.setDate(d.getDate() - (d.getDay() + 2) % 7); //week starts with Sunday??

    // create new date of day before
    var weekOneFriday = new Date(d.getFullYear(), d.getMonth(), d.getDate());
    var weekOneSaturday = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 6);
    var weekTwoFriday = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 7);
    var weekTwoSaturday = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 13);
    var weekThreeFriday = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 14);
    var weekThreeSaturday = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 20);
    var weekFourFriday = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 21);
    var weekFourSaturday = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 27);
    var weekFiveFriday = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 28);
    var weekFiveSaturday = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 34);
    this.weekRanges.push(weekOneSaturday.toDateString() + " to " + weekOneFriday.toDateString());
    this.weekRanges.push(weekTwoSaturday.toDateString() + " to " + weekTwoFriday.toDateString());
    this.weekRanges.push(weekThreeSaturday.toDateString() + " to " + weekThreeFriday.toDateString());
    this.weekRanges.push(weekFourSaturday.toDateString() + " to " + weekFourFriday.toDateString());
    this.weekRanges.push(weekFiveSaturday.toDateString() + " to " + weekFiveFriday.toDateString());
  }

  // formatDate(date: Date)
  // {
  //   return date.getFullYear()+"-"+(date.getMonth()+1) + "-" +(date.getDate() < 10? "0": "") + date.getDate();
  // }

  constructor(private router: Router, public global: GlobalService, public backendService: BackendService) { }

  ngOnInit() {
    this.global.minimiseHeader = true;
    if (sessionStorage.user == null && localStorage.user == null) {
      this.router.navigate(['/login']);
    } else {
      if (localStorage.user != null) {
        sessionStorage.user = localStorage.user;
      }
    }
    this.populateWeekRanges();
    this.sel_weekrange = this.weekRanges[0];
    let user = JSON.parse(sessionStorage.user);

    this.weeklyNamazRequest = new WeeklyNamazReportRequest(user.username, user.report_verify, user.majlis);

    this.sel_majlis = this.global.majalis[0];
    for (var i in this.global.majalis) {
      if (this.global.majalis[i].majlis == user.majlis) {
        this.sel_majlis = this.global.majalis[i];
        if (this.global.majalis[i].has_halqa) {
          if (Object.keys(this.sel_halqa).length == 0) {
            for (var halqaIndex = 0; halqaIndex < this.global.majalis[i].halqas.length; halqaIndex++) {
              if (this.global.majalis[i].halqas[halqaIndex].halqa == "All") {
                this.sel_halqa = this.global.majalis[i].halqas[halqaIndex];
                break;
              }
            }
          }
        }
        break;
      }
    }
  }

}
