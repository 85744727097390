import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BackendService } from '../services/backend.service'
import { GlobalService } from '../services/global.service'
import { LoginRequest } from '../requests-and-responses/login-request';
import { LoginResponse } from '../requests-and-responses/login-response';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public loginRequest: LoginRequest = new LoginRequest("verify_login", "", "");
  public loginResponse: LoginResponse;

  public rememberMe: boolean = false;

  submit() {
    this.global.loading("logging in");
    this.backendService.verifyLogin(this.loginRequest)
      .subscribe(response => {
        this.global.loadingComplete();
        this.loginResponse = response;
        if (this.loginResponse.result == "error") {
          this.global.showMessage("Invalid username or password.", true);
        } else {
          if (this.rememberMe) {
            localStorage.user = JSON.stringify(response.user);
          }
          sessionStorage.user = JSON.stringify(response.user);
          this.router.navigate(['/dashboard']);
        }
      }, err => {
        this.global.showMessage("Failed to send request.", true);
      });
  }

  constructor(private router: Router, private backendService: BackendService, public global: GlobalService) { }

  ngOnInit() {
    this.global.minimiseHeader = false;
    if (localStorage.user != undefined) {
      sessionStorage.user = localStorage.user;
      this.router.navigate(['/dashboard']);
    } else if (sessionStorage.user != undefined) {
      this.router.navigate(['/dashboard']);
    }
  }

}
