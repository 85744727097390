import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '../services/global.service';
import { BackendService } from '../services/backend.service'
import { GetDoorToDoorReportsByMonthRequest } from '../requests-and-responses/get-door-to-door-reports-by-month-request';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-view-door-to-door-reports-by-month',
  templateUrl: './view-door-to-door-reports-by-month.component.html',
  styleUrls: ['./view-door-to-door-reports-by-month.component.css']
})
export class ViewDoorToDoorReportsByMonthComponent implements OnInit {

  public user: any;
  public sel_majlis: any;
  public txt_filterName: string = "";
  public disableMajlisFilter = false;

  public showMessageScreen: boolean = false;
  public modalMessage: string = "";

  public headers: any = [
    { "key": "majlis", "value": "Majlis" },
    { "key": "halqa", "value": "Halqa" },
    { "key": "khaddim", "value": "Khuddam" },
    { "key": "date", "value": "Date" },
    { "key": "response", "value": "Response" },
    { "key": "comments", "value": "Comments" }
  ];

  public doorToDoorMeetingOriginal: any[] = [];
  public doorToDoorMeeting: any[] = [];
  public getDoorToDoorReportsByMonthRequest: any;

  public months: string[] = ["November (prev year)", "December (prev year)", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December", "All"];
  public sel_month = this.months[(new Date().getMonth() + 2) % 12];

  public years: string[] = [new Date().getFullYear() + "", (new Date().getFullYear() - 1) + ""];
  public sel_year: string = this.years[0];

  updateReport() {
    var majlis = "All";
    if (this.user.office == "Qaed" && this.user.username != "zaheerj") {
      majlis = this.user.majlis;
    }
    if (this.sel_month.includes("(prev year)")) {
      this.sel_year = this.years[1];
    }
    var month = this.getZeroIndexMonthFromString(this.sel_month.replace(" (prev year)", "")) + "";
    var year = this.sel_year;
    this.getDoorToDoorReportsByMonthRequest = new GetDoorToDoorReportsByMonthRequest(majlis, month, this.sel_year);
    this.global.loading("fetching reports");
    this.fetchReport();
  }

  getZeroIndexMonthFromString(mon) {
    var d = Date.parse(mon + "1, 2012");
    if (!isNaN(d)) {
      return new Date(d).getMonth();
    }
    return "All";
  }

  constructor(private router: Router, public global: GlobalService, private backendService: BackendService) {
    this.global.loading("fetching reports");
  }

  download() {
    /* generate worksheet */
    // var data = [this.tableHeaderNames,this.tableData[0]];
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.doorToDoorMeeting);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    const wbout: string = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    FileSaver.saveAs(new Blob([wbout]), 'Report_' + this.user.office + this.sel_month + new Date().getTime() + '_.xlsx');
  }

  updateFilters() {
    this.doorToDoorMeeting = this.doorToDoorMeetingOriginal.filter(this.filterData, this);
  }

  filterData(obj) {
    var filterMajlis = this.sel_majlis.majlis == 'All' ? true : obj.majlis == this.sel_majlis.majlis;
    var filterName = this.txt_filterName == '' ? true : (obj.khaddim.toLowerCase().indexOf(this.txt_filterName.toLowerCase()) > -1);
    return filterMajlis && filterName;
  }

  fetchReport() {
    this.backendService.sendAny(this.getDoorToDoorReportsByMonthRequest)
      .subscribe(response => {
        this.global.loadingComplete();
        if (response.result == "error") {
          this.global.showMessage("Invalid request. " + response.error, true);
        } else {
          this.doorToDoorMeetingOriginal = response.data;
          this.doorToDoorMeeting = response.data;
          // this.treatReport();
        }
      }, err => {
        this.global.loadingComplete();
        this.global.showMessage("Failed to fetch report.", true);
      });
  }

  ngOnInit() {
    if (sessionStorage.user == null && localStorage.user == null) {
      this.global.loadingComplete();
      this.router.navigate(['/login']);
    } else {
      if (localStorage.user != null) {
        sessionStorage.user = localStorage.user;
      }
    }

    this.global.minimiseHeader = true;
    this.user = JSON.parse(sessionStorage.user);

    var majlis = "All";
    this.disableMajlisFilter = false;
    if (this.user.office == "Qaed" && this.user.username != "zaheerj") {
      majlis = this.user.majlis;
      this.disableMajlisFilter = true;
    }

    for (var majlisIndex in this.global.majalis) {
      if (majlis == this.global.majalis[majlisIndex].majlis) {
        this.sel_majlis = this.global.majalis[majlisIndex];
      }
    }

    this.getDoorToDoorReportsByMonthRequest = new GetDoorToDoorReportsByMonthRequest(majlis, this.getZeroIndexMonthFromString(this.sel_month)+"", this.sel_year);
    this.fetchReport();
  }

  showMessageScreenModal(meeting)
  {
    this.modalMessage = meeting;
    this.showMessageScreen = true;
  }

  dismissMessage()
  {
    this.showMessageScreen = false;
  }
}
