import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '../services/global.service';
import { BackendService } from '../services/backend.service'
import { GetActivitiesForMonthRequest } from '../requests-and-responses/get-activities-for-month-request';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-view-one-line-reports',
  templateUrl: './view-one-line-reports.component.html',
  styleUrls: ['./view-one-line-reports.component.css']
})
export class ViewOneLineReportsComponent implements OnInit {
  public user: any = {};
  public getActivitiesForMonthRequest: any;

  public reportViews = [
    { "view": "Qaed", "disabled": false },
    { "view": "Muhtamim", "disabled": true },
    { "view": "Sadr", "disabled": true },
    { "view": "All", "disabled": true }
  ];

  public sel_view = this.reportViews[0];
  public sel_majlis: any = this.global.majalis[this.global.majalis.length - 1];
  public sel_halqa: any = {halqa:"All"};
  public officeList: any;
  public sel_office: any;

  public months: string[] = ["November (prev year)", "December (prev year)", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December", "All"];
  public sel_month = this.months[(new Date().getMonth() + 2) % 12];

  public years: number[] = [new Date().getFullYear(), new Date().getFullYear() - 1];
  public sel_year: number = this.years[0];

  public dp_date: any;

  private today = new Date();
  public maxDate: any = {
    year: this.today.getFullYear(),
    month: this.today.getMonth() + 1,
    day: this.today.getDate()
  };;

  public minDate: any = { year: (new Date().getFullYear() - 1), month: 11, day: 1 };

  public tableData: any;
  public tableDataOriginal: any;

  public tableHeaders: string[] = ["date", "majlis", "halqa", "office", "event", "attendance", "comments"];
  public tableHeaderNames: string[] = ["Date", "Majlis", "Halqa", "Office", "Event", "Attendance", "Comments"];
  public customHeader = ["date", "event", "attendance", "comments"];

  public eventTypes: string[] = ["All","Local","Regional","National"];
  public sel_event = this.eventTypes[0];

  constructor(private router: Router, public global: GlobalService, private backendService: BackendService) {
    this.global.loading("fetching reports");
  }

  getTableHeaderNames() {
    return this.tableHeaderNames.filter(this.filterHeader, this)
  }

  getTableHeaders() {
    return this.tableHeaders.filter(this.filterHeader, this)
  }

  filterHeader(obj) {
    return !(this.sel_view.view == "Sadr" && (obj.toLowerCase() == 'majlis' || obj.toLowerCase() == 'halqa'))
      && !(this.sel_view.view == "Muhtamim" && obj.toLowerCase() == 'office')
      && !(this.sel_view.view == "Qaed" && obj.toLowerCase() == 'majlis');
  }

  download() {
    /* generate worksheet */
    // var data = [this.tableHeaderNames,this.tableData[0]];
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.tableData);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    const wbout: string = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    FileSaver.saveAs(new Blob([wbout]), 'Report_' + this.user.office + this.sel_month + new Date().getTime() + '_.xlsx');
  }

  updateFilters() {
    this.tableData = this.tableDataOriginal.filter(this.filterData, this);
    if (this.sel_majlis.majlis != 'All')
    {
      console.log("filtering by majlis: " + this.sel_majlis.majlis);
      this.tableData = this.tableData.filter(function filterMajlis(obj) {
        return obj.majlis == this.sel_majlis.majlis;
      }, this);
    }
    if (this.sel_office.office != "All") {
      console.log("filtering by office: " + this.sel_office.office);
      this.tableData = this.tableData.filter(function filterOffice(obj) {
        return obj.office == this.sel_office.office;
      }, this);
    }
    if (this.sel_event != "All") {
      console.log("filtering by event type: " + this.sel_event);
      this.tableData = this.tableData.filter(function filterOffice(obj) {
        return obj.event_type == this.sel_event;
      }, this);
    }
    if (this.sel_halqa.halqa != "All") {
      console.log("filtering by halqa: " + this.sel_halqa.halqa);
      this.tableData = this.tableData.filter(function filterHalqa(obj) {
        return obj.halqa == this.sel_halqa.halqa;
      }, this);
    }
  }

  updateView() {
    if (this.sel_view.view == "All") {
      this.filterData = function filterData(obj) {
        return obj.majlis != "All";
      };
    } else if (this.sel_view.view == "Sadr") {
      this.sel_majlis.majlis = "All";
      this.filterData = function filterData(obj) {
        return obj.majlis == "All";
      };
    }
    this.tableData = this.tableDataOriginal.filter(this.filterData, this);
    this.updateFilters();
  }

  getMonthFromString(mon) {
    var d = Date.parse(mon + "1, 2012");
    if (!isNaN(d)) {
      return new Date(d).getMonth() + 1;
    }
    return "All";
  }

  updateReport() {
    var majlis = this.user.office.toLowerCase() == "qaed" ? this.sel_majlis.majlis : "All";
    if (this.sel_month.includes("(prev year)")) {
      this.sel_year = this.years[1];
    }
    var month = this.getMonthFromString(this.sel_month.replace(" (prev year)", ""));
    var year = this.sel_year;
    this.getActivitiesForMonthRequest = new GetActivitiesForMonthRequest(majlis, month + "", year + "");
    this.global.loading("fetching reports");
    this.fetchReport();
  }

  fetchReport() {
    this.backendService.sendAny(this.getActivitiesForMonthRequest)
      .subscribe(response => {
        this.global.loadingComplete();
        if (response.result == "error") {
          this.global.showMessage("Invalid request. " + response.error, true);
        } else {
          this.tableDataOriginal = response.data;
          this.tableData = response.data.filter(this.filterData, this);
          this.updateFilters();
        }
      }, err => {
        this.global.loadingComplete();
        this.global.showMessage("Failed to fetch report.", true);
      });
  }

  filterData(obj) {
    return true;
  }

  ngOnInit() {
    if (sessionStorage.user == null && localStorage.user == null) {
      this.global.loadingComplete();
      this.router.navigate(['/login']);
    } else {
      if (localStorage.user != null) {
        sessionStorage.user = localStorage.user;
      }
    }

    this.global.minimiseHeader = true;
    this.user = JSON.parse(sessionStorage.user);
    var date = new Date();
    this.officeList = Object.assign([], this.global.offices);
    this.officeList.push({
      "office": "All",
      "events": ["Other"]
    });
    this.sel_office = this.officeList[this.officeList.length - 1];

    if (this.user.office.toLowerCase().includes("qaed")) {
      console.log("view: qaed");
      this.reportViews[0].disabled = false;
      this.reportViews[1].disabled = true;
      this.reportViews[2].disabled = true;
      this.reportViews[3].disabled = true;
      this.sel_view = this.reportViews[0];
      for (var i in this.global.majalis) {
        if (this.global.majalis[i].majlis == this.user.majlis) {
          this.sel_majlis = this.global.majalis[i];
          if (this.sel_majlis.has_halqa) this.sel_halqa = this.sel_majlis.halqas[0];
          break;
        }
      }
    } else if (this.user.office.toLowerCase().includes("naib sadr")) {
      console.log("view: naib sadr");
      this.reportViews[0].disabled = true;
      this.reportViews[1].disabled = true;
      this.reportViews[2].disabled = true;
      this.reportViews[3].disabled = false;
      this.sel_view = this.reportViews[3];
      this.filterData = function filterData(obj) {
        return obj.majlis != "All";
      };
    } else if (this.user.office.toLowerCase().includes("sadr")) {
      console.log("view: sadr");
      this.reportViews[0].disabled = true;
      this.reportViews[1].disabled = true;
      this.reportViews[2].disabled = false;
      this.reportViews[3].disabled = false;
      this.sel_view = this.reportViews[2];
      this.filterData = function filterData(obj) {
        return obj.majlis == "All";
      };
    } else {
      console.log("view: muhtamim");
      this.reportViews[0].disabled = true;
      this.reportViews[1].disabled = false;
      this.reportViews[2].disabled = true;
      this.reportViews[3].disabled = true;
      this.sel_view = this.reportViews[1];
      for (var i in this.officeList) {
        if (this.officeList[i].office == this.user.office) {
          this.sel_office = this.officeList[i];
          break;
        }
      }
      this.filterData = function filterData(obj) {
        return obj.office.toLowerCase() == this.user.office.toLowerCase();
      };
    }
    var majlis = this.sel_majlis.majlis;
    var month = this.getMonthFromString(this.sel_month);
    var year = this.sel_year;
    this.getActivitiesForMonthRequest = new GetActivitiesForMonthRequest(majlis, month + "", year + "");
    this.fetchReport();
  }
}
