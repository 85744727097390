import { Component, OnInit } from '@angular/core';
import { NgClass } from '@angular/common';
import { GlobalService } from './services/global.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(public global: GlobalService) {
  }

  dismissMessage()
  {
    this.global.hideMessage();
  }

  ngOnInit() {
    if (localStorage.isDarkThemed != null) {
      this.global.isDarkThemed = localStorage.isDarkThemed;
    }
  }
}
