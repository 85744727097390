import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { OneLineReportComponent } from './one-line-report/one-line-report.component';
import { DoorToDoorReportComponent } from './door-to-door-report/door-to-door-report.component';
import { ViewReportsComponent } from './view-reports/view-reports.component';
import { WeeklyNamazReportComponent } from './weekly-namaz-report/weekly-namaz-report.component';
import { MissingReportsComponent } from './missing-reports/missing-reports.component';
import { ViewOneLineReportsComponent } from './view-one-line-reports/view-one-line-reports.component';
import { ViewWeeklyNamazReportsComponent } from './view-weekly-namaz-reports/view-weekly-namaz-reports.component';
import { ViewDoorToDoorReportsComponent } from './view-door-to-door-reports/view-door-to-door-reports.component';
import { ViewDoorToDoorReportsByMonthComponent } from './view-door-to-door-reports-by-month/view-door-to-door-reports-by-month.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'dashboard/one-line-report', component: OneLineReportComponent },
  { path: 'dashboard/door-to-door-report', component: DoorToDoorReportComponent },
  { path: 'dashboard/weekly-namaz-report', component: WeeklyNamazReportComponent },
  { path: 'dashboard/view-reports', component: ViewReportsComponent },
  { path: 'dashboard/view-reports/missing-reports', component: MissingReportsComponent },
  { path: 'dashboard/view-reports/view-one-line-reports', component: ViewOneLineReportsComponent },
  { path: 'dashboard/view-reports/view-weekly-namaz-reports', component: ViewWeeklyNamazReportsComponent },
  { path: 'dashboard/view-reports/view-door-to-door-reports', component: ViewDoorToDoorReportsComponent },
  { path: 'dashboard/view-reports/view-door-to-door-reports/month', component: ViewDoorToDoorReportsByMonthComponent },
  { path: 'login', component: LoginComponent },
  { path: '**', component: LoginComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
