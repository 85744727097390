import {
    Injectable
} from '@angular/core';

@Injectable()
export class NationalAmlaMembersService {
    constructor() {}

    // NOTE: when copy-pasting, don't forget closing braces for class etc.
    public nationalAmlaMembers = [{
            "office": "Sadr",
            "surname": "Jamal Ahmad",
            "other_name": "Murabbi Shameem"
        },
        {
            "office": "Naib Sadr Two",
            "surname": "Joolfoo",
            "other_name": "Waseem"
        },
        {
            "office": "Naib Sadr One",
            "surname": "Soodhun",
            "other_name": "Mohammad Arshad"
        },
        {
            "office": "Mu’tamad",
            "surname": "Taujoo",
            "other_name": "Rizwan Mahmood"
        },
        {
            "office": "Naib Mu’tamad",
            "surname": "Joolfoo",
            "other_name": "Tawheed"
        },
        {
            "office": "Muhtamim Khidmate Khalq",
            "surname": "Sookia",
            "other_name": "Muhammad Usman"
        },
        {
            "office": "Muhtamim Ta’leem",
            "surname": "Soodhun",
            "other_name": "Kaleem Ahmad"
        },
        {
            "office": "Muhtamim Tarbiyyat",
            "surname": "Sooltangos",
            "other_name": "Umar"
        },
        {
            "office": "Naib Muhtamim Tarbiyyat",
            "surname": "Dreepaul",
            "other_name": "Fazle"
        },
        {
            "office": "Muhtamim Maal",
            "surname": "Khudurun",
            "other_name": "Shamsher"
        },
        {
            "office": "Muhtamim Ummumi",
            "surname": "Taujoo",
            "other_name": "Tahir Ahmad"
        },
        {
            "office": "Muhtamim Sihat-e-Jismani",
            "surname": "Taujoo",
            "other_name": "Ata-ul-Ghafir"
        },
        {
            "office": "Naib Muhtamim Sihat-e-Jismani",
            "surname": "Ramjeet",
            "other_name": "Shameer"
        },
        {
            "office": "Muhtamim San’at-o-Tijarat",
            "surname": "Azima",
            "other_name": "Safeer"
        },
        {
            "office": "Muhtamim Tahrik-e-Jadeed",
            "surname": "Elahee",
            "other_name": "Muhammad"
        },
        {
            "office": "Muhtamim Atfal",
            "surname": "Khudurun",
            "other_name": "Murabbi Ahmad Ali"
        },
        {
            "office": "Muhtamim Tabligh",
            "surname": "Khudurun",
            "other_name": "Zaheer-u-din"
        },
        {
            "office": "Naib Muhtamim Tabligh",
            "surname": "Sooltangos",
            "other_name": "Irfaan"
        },
        {
            "office": "Muhtamim Waqare Amal",
            "surname": "Bhunnoo",
            "other_name": "Javed"
        },
        {
            "office": "Muhtamim Tajneed",
            "surname": "Nobee",
            "other_name": "Shaan"
        },
        {
            "office": "Muhtamim Isha’at",
            "surname": "Ramjeet",
            "other_name": "Mohsin"
        },
        {
            "office": "Muhtamim Nau Maba’een",
            "surname": "Meerun",
            "other_name": "Faraz"
        }, {
            "office": "Naib Muhtamim Isha'at",
            "surname": "Aullybux",
            "other_name": "Irfaan"
        },
        {
            "office": "Muhtamim Omoor-e-Talaba",
            "surname": "Jawaheer",
            "other_name": "Mohammad"
        },
        {
            "office": "Muhasib",
            "surname": "Zeadally",
            "other_name": "Hadee"
        },
        {
            "office": "Muhtamim Muqami",
            "surname": "Jowahir",
            "other_name": "Zaheer"
        },
        {
            "office": "Muavin Sadr Waqfe Nau",
            "surname": "Jowaheer",
            "other_name": "Kamil"
        },
        {
            "office": "Muavin Sadr",
            "surname": "Jamal Ahmad",
            "other_name": "Ubeidullah"
        }
    ];

}
