import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limitLengthOfLongFields'
})
export class LimitLengthOfLongFieldsPipe implements PipeTransform {

  transform(value: string, args?: any): string {
    if (value == undefined)
    {
      value = "";
    }
    return value.length<=75? value : value.substring(0,75)+ " [...]";
  }

}
