export class DoorToDoorReportRequest {
  public cmd: string = "add_door_to_door_report";
  public username: string; // reporter
  public password: string; // report_verify
  public majlis: string;
  public halqa: string;
  public canvassers: string;
  public amla_members: string;
  public date: string;
  public response: string;
  public khaddim: string;
  public comments: string;

  constructor(username, password, majlis) {
    this.username = username;
    this.password = password;
    this.majlis = majlis;
  }
}
