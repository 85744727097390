import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../services/global.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  public user: any;
  public khuddam_count: any;
  public atfal_count: any;
  public showCountMessage: boolean = false;

  constructor(public global: GlobalService, private router: Router) { }

  route(route: string) {
    this.router.navigate(['/dashboard' + route]);
  }

  logout() {
    localStorage.removeItem("user");
    sessionStorage.removeItem("user");
    this.router.navigate(['/']);
  }

  ngOnInit() {
    this.global.minimiseHeader = false;
    if (sessionStorage.user == undefined && localStorage.user == undefined) {
      this.router.navigate(['/login']);
    }
    this.user = JSON.parse(sessionStorage.user);
    this.showCountMessage = this.user.majlis != "All";
    if (this.showCountMessage) {
      for (var i in this.global.majalis) {
        if (this.global.majalis[i].majlis == this.user.majlis) {
          this.khuddam_count = this.global.majalis[i].khuddam;
          this.atfal_count = this.global.majalis[i].atfal;
        }
      }
    }
  }

}
