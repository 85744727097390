import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '../services/global.service';
import { BackendService } from '../services/backend.service'
import { GetDoorToDoorReportsRequest } from '../requests-and-responses/get-door-to-door-reports-request';

@Component({
  selector: 'app-view-door-to-door-reports',
  templateUrl: './view-door-to-door-reports.component.html',
  styleUrls: ['./view-door-to-door-reports.component.css']
})
export class ViewDoorToDoorReportsComponent implements OnInit {

  public user: any;
  public sel_majlis: any;
  public txt_filterName: string = "";
  public disableMajlisFilter = false;

  public headers: any = [
    { "key": "majlis", "value": "Majlis" },
    { "key": "khaddim", "value": "Khuddam" },
    { "key": "Positive", "value": "Positive" },
    { "key": "Neutral", "value": "Neutral" },
    { "key": "Negative", "value": "Negative" },
    { "key": "Unavailable", "value": "Unavailable" }
  ];

  public khuddamListOriginal: any[] = [];
  public khuddamList: any[] = [];
  public getDoorToDoorReportsRequest: any;

  constructor(private router: Router, public global: GlobalService, private backendService: BackendService) {
    this.global.loading("fetching reports");
  }

  viewByMonth()
  {
    this.router.navigate(["dashboard/view-reports/view-door-to-door-reports/month"]);
  }

  updateFilters() {
    this.khuddamList = this.khuddamListOriginal.filter(this.filterData, this);
  }

  filterData(obj) {
    var filterMajlis = this.sel_majlis.majlis == 'All' ? true : obj.majlis == this.sel_majlis.majlis;
    var filterName = this.txt_filterName == '' ? true : (obj.khaddim.toLowerCase().indexOf(this.txt_filterName.toLowerCase()) > -1);
    return filterMajlis && filterName;
  }

  fetchReport() {
    this.backendService.sendAny(this.getDoorToDoorReportsRequest)
      .subscribe(response => {
        this.global.loadingComplete();
        if (response.result == "error") {
          this.global.showMessage("Invalid request. " + response.error, true);
        } else {
          this.khuddamListOriginal = response.data;
          this.khuddamList = response.data;
          // this.treatReport();
        }
      }, err => {
        this.global.loadingComplete();
        this.global.showMessage("Failed to fetch report.", true);
      });
  }

  ngOnInit() {
    if (sessionStorage.user == null && localStorage.user == null) {
      this.global.loadingComplete();
      this.router.navigate(['/login']);
    } else {
      if (localStorage.user != null) {
        sessionStorage.user = localStorage.user;
      }
    }

    this.global.minimiseHeader = true;
    this.user = JSON.parse(sessionStorage.user);

    var majlis = "All";
    this.disableMajlisFilter = false;
    if (this.user.office == "Qaed" && this.user.username != "zaheerj") {
      majlis = this.user.majlis;
      this.disableMajlisFilter = true;
    }

    for (var majlisIndex in this.global.majalis) {
      if (majlis == this.global.majalis[majlisIndex].majlis) {
        this.sel_majlis = this.global.majalis[majlisIndex];
      }
    }

    this.getDoorToDoorReportsRequest = new GetDoorToDoorReportsRequest(majlis);
    this.fetchReport();
  }
}
