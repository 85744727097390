import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '../services/global.service';
import { BackendService } from '../services/backend.service'

import { Observable } from 'rxjs/Observable';

@Component({
  selector: 'app-view-reports',
  templateUrl: './view-reports.component.html',
  styleUrls: ['./view-reports.component.css']
})
export class ViewReportsComponent implements OnInit {
  public showLabel: boolean = true;
  public user: any;
  constructor(private router: Router, public global: GlobalService, public backendService: BackendService) { }

  route(route: string) {
    this.router.navigate(['/dashboard/view-reports' + route]);
  }

  ngOnInit() {
    if (sessionStorage.user == null && localStorage.user == null) {
      this.router.navigate(['/login']);
    } else {
      if (localStorage.user != null) {
        sessionStorage.user = localStorage.user;
      }
    }
    this.global.minimiseHeader = true;
    this.user = JSON.parse(sessionStorage.user);

  }

}
