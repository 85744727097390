import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import { AppRoutingModule } from './/app-routing.module'
;
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { OneLineReportComponent } from './one-line-report/one-line-report.component';
import { DoorToDoorReportComponent } from './door-to-door-report/door-to-door-report.component';
import { WeeklyNamazReportComponent } from './weekly-namaz-report/weekly-namaz-report.component';
import { ViewReportsComponent } from './view-reports/view-reports.component';
import { ViewWeeklyNamazReportsComponent } from './view-weekly-namaz-reports/view-weekly-namaz-reports.component';

import { BackendService } from './services/backend.service';
import { GlobalService } from './services/global.service';
import { MajlisService } from './services/majlis.service';
import { NationalAmlaMembersService } from './services/national-amla-members.service';
import { OfficesAndEventsService } from './services/offices-and-events.service';
import { MissingReportsComponent } from './missing-reports/missing-reports.component';
import { ViewOneLineReportsComponent } from './view-one-line-reports/view-one-line-reports.component';
import { ConvertToLocalDatePipe } from './convert-to-local-date.pipe';
import { DisplayPercentagePipe } from './display-percentage.pipe';
import { ViewDoorToDoorReportsComponent } from './view-door-to-door-reports/view-door-to-door-reports.component';
import { ViewDoorToDoorReportsByMonthComponent } from './view-door-to-door-reports-by-month/view-door-to-door-reports-by-month.component';
import { LimitLengthOfLongFieldsPipe } from './limit-length-of-long-fields.pipe';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    DashboardComponent,
    OneLineReportComponent,
    DoorToDoorReportComponent,
    ViewReportsComponent,
    WeeklyNamazReportComponent,
    MissingReportsComponent,
    ViewOneLineReportsComponent,
    ConvertToLocalDatePipe,
    DisplayPercentagePipe,
    ViewWeeklyNamazReportsComponent,
    ViewDoorToDoorReportsComponent,
    ViewDoorToDoorReportsByMonthComponent,
    LimitLengthOfLongFieldsPipe
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    CommonModule,
    NgbModule.forRoot(),
    AppRoutingModule
  ],
  providers: [BackendService, GlobalService, MajlisService, NationalAmlaMembersService, OfficesAndEventsService],
  bootstrap: [AppComponent]
})
export class AppModule { }
