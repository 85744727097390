import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayPercentage'
})
export class DisplayPercentagePipe implements PipeTransform {

  transform(value: number): string {
    return value.toFixed(2) + "%";
  }

}
