import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '../services/global.service';
import { BackendService } from '../services/backend.service'
import { OneLineReportRequest } from '../requests-and-responses/one-line-report-request';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';

@Component({
  selector: 'app-one-line-report',
  templateUrl: './one-line-report.component.html',
  styleUrls: ['./one-line-report.component.css']
})
export class OneLineReportComponent implements OnInit {
  oneLineReportRequest: OneLineReportRequest;
  public user: any;
  public sel_majlis: any = {};
  public sel_halqa: any;
  public sel_office_temp: any;
  public sel_office: any = { events: [] };
  public sel_event: any;
  public sel_event_type: any = "Local";
  public count_khuddam: any;
  public count_atfal: any;
  public comments: string="";
  public dp_date: any;

  private today = new Date();
  public maxDate: any = {
    year: this.today.getFullYear(),
    month: this.today.getMonth() + 1,
    day: this.today.getDate()
  };;
  public minDate: any = { year: (new Date().getFullYear() - 1), month: 11, day: 1 };

  public showLabel: boolean = true;

  public updateOffice() {
    this.sel_office = this.sel_office_temp;
  }
  //
  // public formatOffice = (value: any) => value.office;
  // public searchOffice = (text$: Observable<string>) =>
  //   text$
  //     .debounceTime(200)
  //     .distinctUntilChanged()
  //     .map(term => this.global.offices.filter(v => v.office.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10));
  //
  // // public formatEvent = (value: any) => value.office;
  // public searchEvent = (text$: Observable<string>) =>
  //   text$
  //     .debounceTime(200)
  //     .distinctUntilChanged()
  //     .map(term => this.sel_office.events.slice(0, 10));

  public updateHalqa() {
    if (this.sel_majlis.has_halqa) {
      for (var halqaIndex = 0; halqaIndex < this.sel_majlis.halqas.length; halqaIndex++) {
        if (this.sel_majlis.halqas[halqaIndex].halqa == "All") {
          this.sel_halqa = this.sel_majlis.halqas[halqaIndex];
          break;
        }
      }
    } else {
      this.sel_halqa = undefined;
    }
  }

  public submit() {
    this.oneLineReportRequest.majlis = this.sel_majlis.majlis;
    if (!this.sel_majlis.has_halqa) {
      this.sel_halqa = undefined;
      this.oneLineReportRequest.halqa = "N/A";
    } else {
      this.oneLineReportRequest.halqa = this.sel_halqa.halqa;
    }
    this.oneLineReportRequest.office = this.sel_office.office;
    this.oneLineReportRequest.event_type = this.sel_event_type;
    this.oneLineReportRequest.event = this.sel_event;
    this.dp_date.month = this.dp_date.month < 10 ? "0" + (parseInt(this.dp_date.month)) : this.dp_date.month;
    this.dp_date.day = this.dp_date.day < 10 ? "0" + (parseInt(this.dp_date.day)) : this.dp_date.day;
    this.oneLineReportRequest.date = this.dp_date.year + "-" + this.dp_date.month + "-" + this.dp_date.day;
    this.count_khuddam = this.count_khuddam == null ? 0 : this.count_khuddam;
    this.count_atfal = this.count_atfal == null ? 0 : this.count_atfal;
    this.oneLineReportRequest.attendance_khuddam = this.count_khuddam;
    this.oneLineReportRequest.attendance_atfal = this.count_atfal;
    if (!this.sel_majlis.has_halqa || this.sel_halqa.halqa == "All") {
      this.oneLineReportRequest.total_khuddam_at_date = this.sel_majlis.khuddam;
      this.oneLineReportRequest.total_atfal_at_date = this.sel_majlis.atfal;
    } else {
      this.oneLineReportRequest.total_khuddam_at_date = this.sel_halqa.khuddam;
      this.oneLineReportRequest.total_atfal_at_date = this.sel_halqa.atfal;
    }
    this.oneLineReportRequest.percentage_khuddam = 100 * this.oneLineReportRequest.attendance_khuddam / this.oneLineReportRequest.total_khuddam_at_date;
    if (this.oneLineReportRequest.total_atfal_at_date != 0) {
      this.oneLineReportRequest.percentage_atfal = 100 * this.oneLineReportRequest.attendance_atfal / this.oneLineReportRequest.total_atfal_at_date;
    } else {
      this.oneLineReportRequest.percentage_atfal = 0;
    }
    this.oneLineReportRequest.comments = this.comments;

    this.global.loading("sending report");

    this.sel_office_temp = undefined;
    this.sel_office = { events: [] };
    this.sel_event = undefined;
    this.count_khuddam = null;
    this.count_atfal = null;
    this.comments = "";

    this.backendService.sendAny(this.oneLineReportRequest)
      .subscribe(response => {
        this.global.loadingComplete();
        if (response.result == "error") {
          this.global.showMessage("Invalid request. " + response.error, true);
        } else {
          this.global.showMessage("Report sent, Jazakumullah.", false);
        }
      }, err => {
        this.global.loadingComplete();
        this.global.showMessage("Failed to send report.", true);
      });
  }

  constructor(private router: Router, public global: GlobalService, private backendService: BackendService) {
  }

  ngOnInit() {
    if (sessionStorage.user == null && localStorage.user == null) {
      this.router.navigate(['/login']);
    } else {
      if (localStorage.user != null) {
        sessionStorage.user = localStorage.user;
      }
    }
    this.global.minimiseHeader = true;
    this.user = JSON.parse(sessionStorage.user);
    this.oneLineReportRequest = new OneLineReportRequest(this.user.username, this.user.report_verify, this.user.majlis);
    this.sel_majlis = this.global.majalis[0];
    for (var i in this.global.majalis) {
      if (this.global.majalis[i].majlis == this.user.majlis) {
        this.sel_majlis = this.global.majalis[i];
        if (this.global.majalis[i].has_halqa) {
          for (var halqaIndex = 0; halqaIndex < this.global.majalis[i].halqas.length; halqaIndex++) {
            if (this.global.majalis[i].halqas[halqaIndex].halqa == this.user.halqa) {
              this.sel_halqa = this.global.majalis[i].halqas[halqaIndex];
              break;
            }
          }
          if (Object.keys(this.sel_halqa).length == 0) {
            for (var halqaIndex = 0; halqaIndex < this.global.majalis[i].halqas.length; halqaIndex++) {
              if (this.global.majalis[i].halqas[halqaIndex].halqa == "All") {
                this.sel_halqa = this.global.majalis[i].halqas[halqaIndex];
                break;
              }
            }
          }
        }
        break;
      }
    }

    this.dp_date = {
      year: this.today.getFullYear(),
      month: this.today.getMonth() + 1,
      day: this.today.getDate()
    };

  }

}
