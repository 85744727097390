import { Injectable } from '@angular/core';

@Injectable()
export class OfficesAndEventsService {

  constructor() { }

  public offices =
  [{
    "office": "Atfal",
    "restricted": false,
    "events": ["Canvassing", "Class", "Outing", "Sports", "Tarbiyyat Camp", "Other"]
  }, {
    "office": "Isha’at",
    "restricted": false,
    "events": ["Canvassing", "Magazine", "Newsletter", "Poster", "Teaser", "Video", "Other"]
  }, {
    "office": "Khidmate Khalq",
    "restricted": false,
    "events": ["Blood donation", "Care For Others", "Clothes distribution", "Feed a Family", "Visit elderly/sick", "Other"]
  }, {
    "office": "Maal",
    "restricted": false,
    "events": ["Canvassing", "Chanda Collection", "Deposit funds in office", "Send reminders", "Other"]
  }, {
    "office": "Mu’tamad",
    "restricted": false,
    "events": ["Convocation", "Majlis-e-Amla", "Minutes", "Other"]
  }, {
    "office": "Muhasib",
    "restricted": true,
    "events": ["Other"]
  }, {
    "office": "Nau Maba’een",
    "restricted": false,
    "events": ["Class", "Meeting", "Visit new convert", "Other"]
  }, {
    "office": "Omoor-e-Talaba",
    "restricted": false,
    "events": ["Other"]
  }, {
    "office": "San’at-o-Tijarat",
    "restricted": false,
    "events": ["Other"]
  }, {
    "office": "Sihat-e-Jismani",
    "restricted": false,
    "events": ["Babyfoot", "Camping", "Fitness class", "Football", "Fun games", "Hiking", "Indoor games", "Outing", "Pool/Billard", "Swimming", "Table Tennis", "Treasure Hunt", "Video games", "Volleyball", "Other"]
  }, {
    "office": "Ta’leem",
    "restricted": false,
    "events": ["Achievement ", "Activity", "Canvassing", "Class", "Exams", "Khuddam Contact", "Meeting", "Namaaz Class", "One-to-One Namaaz Class", "One-to-One Quraan Class", "Quraan Class", "Other"]
  }, {
    "office": "Tabligh",
    "restricted": false,
    "events": ["Bookstall", "Class", "Conference", "Contact Follow up", "DaeenAllah meeting", "Door-to-door", "Exhibition", "Pamphlet distribution", "Tabligh through media", "Waqfe-arzi", "Other"]
  }, {
    "office": "Tahrik-e-Jadeed",
    "restricted": false,
    "events": ["Canvassing", "Collection of contribution", "Collection of forms", "Contact khuddam", "Distribution of forms", "Other"]
  }, {
    "office": "Tajneed",
    "restricted": false,
    "events": ["Collection of data", "Update database", "Other"]
  }, {
    "office": "Tarbiyyat",
    "restricted": false,
    "events": ["Canvassing", "Class", "Contact khuddam", "Khuddam meeting", "Namaz Coaches", "Tarbiyyat Camp", "Other"]
  }, {
    "office": "Ummumi",
    "restricted": false,
    "events": ["Security", "Security for Jummah", "Other"]
  }, {
    "office": "Waqare Amal",
    "restricted": false,
    "events": ["Cleaning", "Food distribution", "Loading/unloading", "Setup", "Other"]
  }, {
    "office": "Waqfe Nau",
    "restricted": true,
    "events": ["Other"]
  }, {
    "office": "Muavin Sadr",
    "restricted": true,
    "events": ["Contact Muhtamim", "Contact Qaedeen", "Send reminders", "Update report system", "Other"]
  }, {
    "office": "Naib Sadr (A.S)",
    "restricted": true,
    "events": ["Contact Qaedeen", "Other"]
  }, {
    "office": "Naib Sadr (W.J)",
    "restricted": true,
    "events": ["Contact Qaedeen", "Other"]
  }];
}
