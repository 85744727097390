import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MajlisService } from './majlis.service';
import { NationalAmlaMembersService } from './national-amla-members.service';
import { OfficesAndEventsService } from './offices-and-events.service';

@Injectable()
export class GlobalService {

  public isDarkThemed: boolean = false;
  public minimiseHeader: boolean = false;
  public title: string = 'MKA Reporting System';
  public showLoadingScreen: boolean = false;
  public showMessageScreen: boolean = false;
  public isError: boolean = false;
  public modalMessage: string = "";
  public loadingMessage: string = "";

  public majalis: any[];
  public nationalAmlaMembers: any[];
  public offices: any[];

  constructor(
    private http: HttpClient,
    private majlisService: MajlisService,
    private nationalAmlaMembersService: NationalAmlaMembersService,
    private officesAndEventsService: OfficesAndEventsService
  ) {
    this.majalis = majlisService.majalis;
    this.nationalAmlaMembers = nationalAmlaMembersService.nationalAmlaMembers;
    this.offices = officesAndEventsService.offices;
  }
  
  public loading(message: string) {
    this.showLoadingScreen = true;
    this.loadingMessage = message;
  }

  public loadingComplete() {
    this.showLoadingScreen = false;
  }

  public showMessage(message:string, isError: boolean)
  {
    this.showMessageScreen = true;
    this.isError = isError;
    this.modalMessage= message;
  }

  public hideMessage()
  {
    this.showMessageScreen = false;
    this.isError = false;
    this.modalMessage= "";
  }
}
