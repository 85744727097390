import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '../services/global.service';
import { BackendService } from '../services/backend.service'
import { DoorToDoorReportRequest } from '../requests-and-responses/door-to-door-report-request';

@Component({
  selector: 'app-door-to-door-report',
  templateUrl: './door-to-door-report.component.html',
  styleUrls: ['./door-to-door-report.component.css']
})
export class DoorToDoorReportComponent implements OnInit {
  public showLabel: boolean = true;

  public doorToDoorReportRequest: DoorToDoorReportRequest;

  public sel_majlis: any;
  public sel_halqa: any = {};
  public sel_amla_member: any = "0";
  public sel_response: any = "Positive";
  public amlaMembers: string[] = [];
  public comments: string;
  public canvassers: string[] = [];
  public txt_addCanvasser: string;
  public khuddam: string[] = [];
  public txt_addKhaddim: string;
  public dp_date: any;

  private today = new Date();
  public maxDate: any = {
    year: this.today.getFullYear(),
    month: this.today.getMonth() + 1,
    day: this.today.getDate()
  };;
  public minDate: any = { year: (new Date().getFullYear() - 1), month: 11, day: 1 };

  public addCanvasser(canvasser) {
    this.txt_addCanvasser = "";
    if (canvasser == null || canvasser == "") return false;

    for (var i = 0; i < this.canvassers.length; i++) {
      if (canvasser == this.canvassers[i]) {
        this.global.showMessage("You have already added this name", true);
        return false;
      }
    }
    this.canvassers.push(canvasser);
    return true;
  }

  public removeCanvasser(canvasser) {
    const index = this.canvassers.indexOf(canvasser);
    if (index !== -1) {
      this.canvassers.splice(index, 1);
    }
  }

  public addKhuddam(khaddim) {
    this.txt_addKhaddim = "";
    if (khaddim == null || khaddim == "") return false;

    for (var i = 0; i < this.khuddam.length; i++) {
      if (khaddim == this.khuddam[i]) {
        this.global.showMessage("You have already added this name", true);
        return false;
      }
    }
    this.khuddam.push(khaddim);
    return true;
  }

  public removeKhuddam(khaddim) {
    const index = this.khuddam.indexOf(khaddim);
    if (index !== -1) {
      this.khuddam.splice(index, 1);
    }
  }


  public sortName(a, b) {
    return (a.other_name + ' ' + a.surname).localeCompare((b.other_name + ' ' + b.surname))
  }

  public addAmlaMember(e) {
    if (this.sel_amla_member != null && this.sel_amla_member != "") {
      var exists = false;
      for (var i = 0; i < this.amlaMembers.length; i++) {
        if (this.sel_amla_member == this.amlaMembers[i]) {
          this.global.showMessage("You have already added this name", true);
          exists = true;
          break;
        }
      }
      if (!exists) {
        this.amlaMembers.push(this.sel_amla_member);
      }
      this.sel_amla_member = "0";
      e.target.value = "0";
    }
  }

  public removeAmlaMember(amlaMember) {
    const index = this.amlaMembers.indexOf(amlaMember);
    if (index !== -1) {
      this.amlaMembers.splice(index, 1);
    }
  }

  public updateHalqa() {
    if (this.sel_majlis.has_halqa) {
      for (var halqaIndex = 0; halqaIndex < this.sel_majlis.halqas.length; halqaIndex++) {
        if (this.sel_majlis.halqas[halqaIndex].halqa == "All") {
          this.sel_halqa = this.sel_majlis.halqas[halqaIndex];
          break;
        }
      }
    } else {
      this.sel_halqa = undefined;
    }
  }

  public submit() {
    if (this.sel_majlis.majlis == "All")
    {
      var khuddam = this.khuddam.length > 1? "Khuddam" : "Khaddim"
      this.global.showMessage("Please input the majlis of the " + khuddam + " instead of using 'All'.", true);
      return false;
    }
    this.doorToDoorReportRequest.majlis = this.sel_majlis.majlis;
    if (!this.sel_majlis.has_halqa) {
      this.sel_halqa = undefined;
      this.doorToDoorReportRequest.halqa = "N/A";
    } else {
      this.doorToDoorReportRequest.halqa = this.sel_halqa.halqa;
    }
    this.dp_date.month = this.dp_date.month < 10 ? "0" + (parseInt(this.dp_date.month)) : this.dp_date.month;
    this.dp_date.day = this.dp_date.day < 10 ? "0" + (parseInt(this.dp_date.day)) : this.dp_date.day;
    this.doorToDoorReportRequest.date = this.dp_date.year + "-" + this.dp_date.month + "-" + this.dp_date.day;
    this.doorToDoorReportRequest.response = this.sel_response;
    this.doorToDoorReportRequest.comments = this.comments == undefined ? "" : this.comments;

    var canvassersString = "";
    for (var c = 0; c < this.canvassers.length - 1; c++) {
      canvassersString += this.canvassers[c] + ", ";
    }
    canvassersString += this.canvassers[this.canvassers.length - 1];
    this.doorToDoorReportRequest.canvassers = canvassersString;

    var amlaMembersString = "";
    for (var a = 0; a < this.amlaMembers.length - 1; a++) {
      amlaMembersString += this.amlaMembers[a] + ", ";
    }
    amlaMembersString += this.amlaMembers[this.amlaMembers.length - 1];
    this.doorToDoorReportRequest.amla_members = amlaMembersString;

    var khuddamString = "";
    for (var a = 0; a < this.khuddam.length - 1; a++) {
      khuddamString += this.khuddam[a] + ",";
    }
    khuddamString += this.khuddam[this.khuddam.length - 1];
    this.doorToDoorReportRequest.khaddim = khuddamString;

    this.global.loading("sending report");
    this.backendService.sendAny(this.doorToDoorReportRequest)
      .subscribe(response => {
        this.global.loadingComplete();
        if (response.result == "error") {
          this.global.showMessage("Invalid request. " + response.error, true);
        } else {
          this.global.showMessage("Report sent, Jazakumullah.", false);
        }
      }, err => {
        this.global.loadingComplete();
        this.global.showMessage("Failed to send report.", true);
      });

    this.khuddam = [];
    this.comments = "";
    this.sel_response = "Positive";

  }

  constructor(private router: Router, public global: GlobalService, public backendService: BackendService) { }

  ngOnInit() {
    this.global.minimiseHeader = true;
    if (sessionStorage.user == null && localStorage.user == null) {
      this.router.navigate(['/login']);
    } else {
      if (localStorage.user != null) {
        sessionStorage.user = localStorage.user;
      }
    }
    let user = JSON.parse(sessionStorage.user);

    this.doorToDoorReportRequest = new DoorToDoorReportRequest(user.username, user.report_verify, user.majlis);

    // this.sel_majlis = this.global.majalis[0];
    for (var i in this.global.majalis) {
      if (this.global.majalis[i].majlis == user.majlis) {
        this.sel_majlis = this.global.majalis[i];
        if (this.global.majalis[i].has_halqa) {
          if (Object.keys(this.sel_halqa).length == 0) {
            for (var halqaIndex = 0; halqaIndex < this.global.majalis[i].halqas.length; halqaIndex++) {
              if (this.global.majalis[i].halqas[halqaIndex].halqa == "All") {
                this.sel_halqa = this.global.majalis[i].halqas[halqaIndex];
                break;
              }
            }
          }
        }
        break;
      }
    }
    this.dp_date = {
      year: this.today.getFullYear(),
      month: this.today.getMonth() + 1,
      day: this.today.getDate()
    };
  }
}
