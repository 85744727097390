import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '../services/global.service';
import { BackendService } from '../services/backend.service'
import { GetWeeklyNamazReportsRequest } from '../requests-and-responses/get-weekly-namaz-reports-request';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-view-weekly-namaz-reports',
  templateUrl: './view-weekly-namaz-reports.component.html',
  styleUrls: ['./view-weekly-namaz-reports.component.css']
})
export class ViewWeeklyNamazReportsComponent implements OnInit {

  public user: any;

  public getWeeklyNamazReportsRequest: any;
  public tableDataOriginal: any[] = [];

  public chartFajr: any;
  public chartMaghrib: any;
  public chartIsha: any;

  public weekRanges: string[] = [];
  public weekRangesLabels: string[] = [];

  public colors: string[] = ["#ff1f5a", "#b41fff", "#b89eff", "#5e5eff", "#b4ff5e", "#ff1f1f", "#1df2e4", "#e68e9a", "#e51c87", "#e68ec8", "#55b5e6", "#8ee0e6", "#55e68f", "#d950d0", "#cc6018", "#4777bf",
    "#a7bf77", "#bfb417", "#bf8717", "#b2936f", "#8c1121", "#32118c", "#578c82", "#475e73"];

  constructor(private router: Router, public global: GlobalService, private backendService: BackendService) {
    this.global.loading("fetching reports");
  }

  fetchReport() {
    this.backendService.sendAny(this.getWeeklyNamazReportsRequest)
      .subscribe(response => {
        this.global.loadingComplete();
        if (response.result == "error") {
          this.global.showMessage("Invalid request. " + response.error, true);
        } else {
          this.tableDataOriginal = response.data;
          this.treatReport();
        }
      }, err => {
        this.global.loadingComplete();
        this.global.showMessage("Failed to fetch report.", true);
      });
  }

  treatReport() {
    this.global.loading("generating charts")
    if (this.user.office == 'Qaed') {
      this.createChartForMajlis();
    } else {
      this.createChartForAll()
    }
    this.global.loadingComplete();
  }

  createChartForAll() {
    var datasetFajr = [];
    var datasetMaghrib = [];
    var datasetIsha = [];
    for (var m in this.global.majalis) {
      var dataFajr = [-5, -5, -5, -5, -5];
      var dataMaghrib = [-5, -5, -5, -5, -5];
      var dataIsha = [-5, -5, -5, -5, -5];
      for (var index in this.tableDataOriginal) {
        if (this.tableDataOriginal[index].majlis == this.global.majalis[m].majlis) {
          for (var w in this.weekRanges) {
            if (this.tableDataOriginal[index].week_text.indexOf(this.weekRanges[w]) > -1) {
              dataFajr[w] = this.tableDataOriginal[index].per_khuddam_fajr;
              dataMaghrib[w] = this.tableDataOriginal[index].per_khuddam_maghrib;
              dataIsha[w] = this.tableDataOriginal[index].per_khuddam_isha;
            }
          }
        }
      }

      datasetFajr.push({
        data: dataFajr,
        label: this.global.majalis[m].short_code,
        borderColor: this.colors[m],
        fill: false,
        backgroundColor: this.colors[m],
        hidden: dataFajr[0] == -5 && dataFajr[1] == -5 && dataFajr[2] == -5 && dataFajr[3] == -5 && dataFajr[4] == -5
      });
      datasetMaghrib.push({
        data: dataMaghrib,
        label: this.global.majalis[m].short_code,
        borderColor: this.colors[m],
        fill: false,
        backgroundColor: this.colors[m],
        hidden: dataMaghrib[0] == -5 && dataMaghrib[1] == -5 && dataMaghrib[2] == -5 && dataMaghrib[3] == -5 && dataMaghrib[4] == -5
      });
      datasetIsha.push({
        data: dataIsha,
        label: this.global.majalis[m].short_code,
        borderColor: this.colors[m],
        fill: false,
        backgroundColor: this.colors[m],
        hidden: dataIsha[0] == -5 && dataIsha[1] == -5 && dataIsha[2] == -5 && dataIsha[3] == -5 && dataIsha[4] == -5
      });
    }
    var chartDataFajr = {
      datasets: datasetFajr,
      labels: this.weekRangesLabels,
    };
    var chartDataMaghrib = {
      datasets: datasetMaghrib,
      labels: this.weekRangesLabels,
    };
    var chartDataIsha = {
      datasets: datasetIsha,
      labels: this.weekRangesLabels,
    };

    this.chartFajr = new Chart('canvasFajr', this.getChartData(chartDataFajr, "Fajr"));
    this.chartMaghrib = new Chart('canvasMaghrib', this.getChartData(chartDataMaghrib, "Maghrib"));
    this.chartIsha = new Chart('canvasIsha', this.getChartData(chartDataIsha, "Isha"));
  }

  createChartForMajlis() {
    var datasetMajlis = [];
    var dataFajr = [-5, -5, -5, -5, -5];
    var dataMaghrib = [-5, -5, -5, -5, -5];
    var dataIsha = [-5, -5, -5, -5, -5];
    for (var index in this.tableDataOriginal) {
      for (var w in this.weekRanges) {
        if (this.tableDataOriginal[index].week_text.indexOf(this.weekRanges[w]) > -1) {
          dataFajr[w] = this.tableDataOriginal[index].per_khuddam_fajr;
          dataMaghrib[w] = this.tableDataOriginal[index].per_khuddam_maghrib;
          dataIsha[w] = this.tableDataOriginal[index].per_khuddam_isha;
        }
      }
    }

    datasetMajlis.push({
      data: dataFajr,
      label: "Fajr",
      borderColor: this.colors[1],
      fill: false,
      backgroundColor: this.colors[1],
      hidden: dataFajr[0] == -5 && dataFajr[1] == -5 && dataFajr[2] == -5 && dataFajr[3] == -5 && dataFajr[4] == -5
    });

    datasetMajlis.push({
      data: dataMaghrib,
      label: "Maghrib",
      pointStyle: "triangle",
      radius: 7,
      borderColor: this.colors[0],
      fill: false,
      backgroundColor: this.colors[0],
      hidden: dataMaghrib[0] == -5 && dataMaghrib[1] == -5 && dataMaghrib[2] == -5 && dataMaghrib[3] == -5 && dataMaghrib[4] == -5
    });

    datasetMajlis.push({
      data: dataIsha,
      label: "Isha",
      pointStyle: "rectRot",
      radius: 9,
      borderColor: this.colors[2],
      fill: false,
      backgroundColor: this.colors[2],
      hidden: dataIsha[0] == -5 && dataIsha[1] == -5 && dataIsha[2] == -5 && dataIsha[3] == -5 && dataIsha[4] == -5
    });

    var chartDataFajr = {
      datasets: datasetMajlis,
      labels: this.weekRangesLabels,
    };

    this.chartFajr = new Chart('canvasFajr', this.getChartData(chartDataFajr, ""));
  }

  getChartData(data, yLabelPrefix) {
    var fontColorGeneral = this.global.isDarkThemed ? "#DDD" : "#212529";
    var gridLineColor = this.global.isDarkThemed ? 'rgba(220, 220, 220, 0.5)' : 'rgba(100, 100, 100, 0.5)';
    var gridZeroLineColor = this.global.isDarkThemed ? 'rgba(220, 220, 220, 1)' : 'rgba(100, 100, 100, 1)';

    var chartData = {
      type: 'line',
      data: data,
      options: {
        legend: {
          labels: {
            fontColor: fontColorGeneral
          },
          display: true
        },
        elements: {
          line: {
            fill: false,
            borderColor: '#fff',
            backgroundColor: '#fff',
            tension: 0
          },
          point: {
            radius: 5,
            backgroundColor: '#f00'
          }
        },
        scales: {
          xAxes: [{
            ticks: {
              fontColor: fontColorGeneral
            },
            scaleLabel: {
              display: true,
              fontColor: fontColorGeneral,
              labelString: "-5% -> Report not sent"
            },
            gridLines: {
              color: gridLineColor,
              zeroLineColor: gridZeroLineColor
            }
          }],
          yAxes: [{
            ticks: {
              min: -5,
              fontColor: fontColorGeneral
            },
            scaleLabel: {
              display: true,
              labelString: yLabelPrefix + " Presence %",
              fontColor: fontColorGeneral
            },
            gridLines: {
              color: gridLineColor,
              zeroLineColor: gridZeroLineColor
            }
          }]
        }
      }
    };
    return chartData;
  }

  ngOnInit() {
    if (sessionStorage.user == null && localStorage.user == null) {
      this.global.loadingComplete();
      this.router.navigate(['/login']);
    } else {
      if (localStorage.user != null) {
        sessionStorage.user = localStorage.user;
      }
    }

    this.global.minimiseHeader = true;
    this.user = JSON.parse(sessionStorage.user);

    var d = new Date();
    // set to last Friday of this week (or today if it's Friday)
    d.setDate(d.getDate() - (d.getDay() + 2) % 7); //week starts with Sunday??

    // create new date of day before
    var weekOneSaturday = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 6);
    var weekOneFriday = new Date(d.getFullYear(), d.getMonth(), d.getDate());

    var weekTwoSaturday = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 13);
    var weekTwoFriday = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 7);

    var weekThreeSaturday = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 20);
    var weekThreeFriday = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 14);

    var weekFourSaturday = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 27);
    var weekFourFriday = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 21);

    var weekFiveSaturday = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 34);
    var weekFiveFriday = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 28);

    this.weekRanges.push(weekFiveSaturday.toDateString() + " to " + weekFiveFriday.toDateString());
    this.weekRanges.push(weekFourSaturday.toDateString() + " to " + weekFourFriday.toDateString());
    this.weekRanges.push(weekThreeSaturday.toDateString() + " to " + weekThreeFriday.toDateString());
    this.weekRanges.push(weekTwoSaturday.toDateString() + " to " + weekTwoFriday.toDateString());
    this.weekRanges.push(weekOneSaturday.toDateString() + " to " + weekOneFriday.toDateString());

    this.weekRangesLabels.push(weekFiveFriday.toDateString());
    this.weekRangesLabels.push(weekFourFriday.toDateString());
    this.weekRangesLabels.push(weekThreeFriday.toDateString());
    this.weekRangesLabels.push(weekTwoFriday.toDateString());
    this.weekRangesLabels.push(weekOneFriday.toDateString());
    var majlis = "All";

    // var query = "SELECT * WHERE (H LIKE '" + weekOneSaturday.toDateString() + "%' OR H LIKE '" + weekTwoSaturday.toDateString() + "%' OR H LIKE '" + weekThreeSaturday.toDateString()+ "%' OR H LIKE '" + weekFourSaturday.toDateString() + "%' OR H LIKE '" + weekFiveSaturday.toDateString() + "%') ";
    var weekListEncoded = "";

    for (var i=0; i < 5; i++) {
      var separator = i < 4 ? "," : "";
      weekListEncoded += ((this.weekRanges[i]) + separator);
    }
    console.log(weekListEncoded);
    if (this.user.office == "Qaed") {
      majlis = this.user.majlis;
    }
    this.getWeeklyNamazReportsRequest = new GetWeeklyNamazReportsRequest(majlis, encodeURIComponent(weekListEncoded));
    this.fetchReport();
  }
}
