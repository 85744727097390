import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '../services/global.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  public isDev: boolean;
  constructor(public global: GlobalService, private router: Router) {
    this.isDev = !environment.production;
  }

  goToDashboard(){
    this.router.navigate(['/dashboard']);
  }

  toggleDarkTheme(){
      this.global.isDarkThemed = !this.global.isDarkThemed;
      localStorage.isDarkThemed = this.global.isDarkThemed;
  }

  ngOnInit() { }

}
